<template>
    <v-app>
        
    </v-app>
</template>

<script>
export default {
    name: 'HomePage',
    mounted(){
        if(window.location.href.indexOf('rezeyle') == -1){
            window.location.href = 'https://rezeyle.com.br'
            return
        }
        window.location.href = 'https://nagendaadm.vercel.app/'
    }
}
</script>

<style scoped>

</style>