<template>
    <v-app>
        <img :src="qrcode" style="width: 300px;" alt="" v-if="connected == false">

        <h2 v-if="connected">CONECTADO COM SUCESSO!</h2>
    </v-app>
</template>

<script>
import axios from 'axios'
const delay = ms => new Promise(res => setTimeout(res, ms));
import config from '../config';

export default {
    name: 'Qrcode',
    data(){
        return{
            qrcode: '',
            connected: false
        }
    },
    async mounted(){
        await this.init();
    },
    methods:{
        async init(){
            await this.createInstance();
            await this.generate();
        },
        async createInstance(){
            try {
                let response = await axios.post(`${config.baseUrl}criaInstanciaR/${this.$route.params.key}`);
                this.qrcode = response.data.base64;
            } catch (error) {
                console.log(error)
            }
        },
        async generate(){
            try {
                let response = await axios.get(`${config.baseUrl}iniciaR/${this.$route.params.key}`);
                if(response.data.instance){
                    this.connected = true;
                    return;
                }
                if(response.data.base64 == ""){
                    await this.generate();
                }else{
                    this.qrcode = response.data.base64;
                }
                await delay(5000)
                await this.init();
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>

</style>